import { Box } from "@mui/material";
import React from "react";
import { PetrolData } from "../../../data/mockData";
import Service from "../Service";
import SEO from "../../../hooks/SEO";
const Petrol = () => {
  return (
    <>
      <>
        {" "}
        <SEO
          title="CEDG Company  | INTERNATIONAL FOR OILING"
          description="CEDG International For Oiling Landing Page."
          name="CEDG Company."
          type="Service_page"
        />
        <Box key={PetrolData.id}>
          <Service
            header={PetrolData.header}
            MainImg={PetrolData.MainImg}
            title={PetrolData.title}
            first={PetrolData.first}
            second={PetrolData.second}
            subtitle={PetrolData.subtitle}
            last={PetrolData.last}
            img={PetrolData.img}
            listItem={PetrolData.listItem}
            listItem2={PetrolData.listItem2}
            listItem3={PetrolData.listItem3}
          />
        </Box>
      </>
    </>
  );
};

export default Petrol;
