import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import StepCard from "../info-card/StepCard/StepCard";

const HWW = () => {
  const data = [
    {
      id: 1,
      number: "1",
      title: "Prevent Contamination",
      description:
        "With CEDG Company Project, an average of 3 tonnes of Mercury per year is prevented from being released into the Environment",
    },
    {
      id: 2,
      number: "2",
      title: "Frindly Practices",
      description:
        "The Artisanal Miners begins to practice the activity based on Good practices of occupational safety and the caring of the environment.",
    },
    {
      id: 3,
      number: "3",
      title: "Natural Resources",
      description:
        "Continue to optimize natural resources, including wáter, and creates wealth for the miners, their families, local and national government.",
    },
    {
      id: 4,
      number: "4",
      title: "Sustainability",
      description:
        "Meeting the needs of the present without compromising the ability of future generations to meet and Allows the development of a sustainable environmental activity.",
    },
  ];
  return (
    <>
      {" "}
      <Box
        sx={{
          paddingLeft: { xl: "2%", lg: "0%", xs: "0" },
          textAlign: "center",
          backgroundColor: "#161616",
          paddingBottom: "10%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            paddingTop: { lg: "5%", xs: "10%" },
          }}
        >
          {" "}
          <Box sx={{ textAlign: "center", marginLeft: "5%" }}>
            <Box
              sx={{
                backgroundColor: "#fff",
                marginX: "45%",
                borderRadius: "25px",
                padding: "10px",
                marginBottom: "2%",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { lg: "20px", md: "23px", xs: "23px" },
                  fontWeight: "400",
                  color: "#0249ae",
                }}
              >
                Services
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="p"
                sx={{
                  fontSize: { xl: "80px", lg: "80px", xs: "2.3rem" },
                  fontWeight: "700",
                  lineHeight: "1.1em",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                What Makes Our,
                <br /> Project Different
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  paddingTop: "50px",
                  paddingBottom: "20px",
                  paddingX: { lg: "20%", xl: "20%", xs: "10%" },
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "20px", fontWeight: "400", color: "#fff" }}
                >
                  We're pushing boundaries and breaking the mold with our
                  innovative approach. Our project will revolutionize the
                  industry and make a real impact.
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              xl={12}
              lg={12}
              sx={{ marginLeft: { xl: "5%", lg: "0", xs: "15%" } }}
            >
              {data.map((item) => (
                <Box
                  sx={{
                    marginY: "20px",
                    marginX: "2%",
                    display: "flex",
                    textAlign: "center",
                  }}
                  key={item.id}
                >
                  <Grid item xl={12} lg={12}>
                    <StepCard
                      number={item.number}
                      title={item.title}
                      description={item.description}
                    />
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HWW;
