import { Box, Grid } from "@mui/material";
import React from "react";
import Heading from "../../components/Heading/Heading";
import GoldMine from "../../assets/Gold-Mine.png";
import CHeader from "../../components/CHeader/CHeader";
import { MediaData } from "../../data/mockData";
import MediaCard from "../../components/info-card/MediaCard";
import SEO from "../../hooks/SEO";

const Services = () => {
  return (
    <>
      {" "}
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="landing_page"
      />
      <Box sx={{ backgroundColor: "#161616", textAlign: "center" }}>
        <Box>
          <Heading head="Services" img={GoldMine} />
        </Box>
        <Box sx={{ paddingTop: "10%", paddingX: "4%" }}>
          <CHeader
            Tag="Heard Services"
            Title="Exploring the World of Mining through Our Insightful Blog!"
            Description="Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam bibendum pharetra augue, at tempus ex tincidunt vel. Aenean rutrum auctor leo at suscipit. Curabitur pulvinar libero mi, vitae tristique eros viverra non."
          />
        </Box>
        <Box sx={{ width: "100%", paddingY: "10%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ paddingX: "5%" }}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
                margin: "20px",
              }}
              rowSpacing={5}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {MediaData.map((item) => (
                <Box key={item.id} sx={{}}>
                  <Grid item>
                    <MediaCard
                      video={item.video}
                      title={item.title}
                      description={item.description}
                      to={item.to}
                      Link={item.link}
                    />
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Services;
