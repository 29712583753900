import { Box, Button, Typography } from "@mui/material";
import React from "react";

const Social = (props) => {
  const { icon, title, link } = props;
  return (
    <>
      <Box>
        <Button
          href={link}
          startIcon={icon}
          sx={{
            backgroundColor: "#fff",

            "&:hover": { backgroundColor: "#ED7D31", color: "#fff" },
          }}
        >
          <Typography variant="p" sx={{ fontSize: "10px", fontWeight: "700" }}>
            {title}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default Social;
