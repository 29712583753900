import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

const Contact = () => {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form Submitted");
    navigate("/");
  };
  return (
    <>
      <Box
        sx={{
          maxWidth: { xl: "70%", lg: "70%", xs: "75%" },
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container lg={12} spacing={1}>
            <Grid item xl={6} xs={12} lg={12}>
              <TextField label="first name" fullWidth required />
            </Grid>
            <Grid item xl={6} xs={12} lg={12}>
              <TextField label="last name" fullWidth required />
            </Grid>
            <Grid item xl={12} xs={12} lg={12}>
              <TextField type="email" label="email" fullWidth required />
            </Grid>
            <Grid item xl={12} xs={12} lg={12}>
              <TextField
                label="message"
                fullWidth
                multiline
                rows={4}
                required
              />
            </Grid>
            <Grid item xl={12} xs={12} lg={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#0249ae", borderRadius: "25px" }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default Contact;
