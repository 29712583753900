import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { NavMenu } from "./index";
import MapIcon from "@mui/icons-material/Map";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import logo from "../../assets/cedg.png";
//#0249ae
const Header = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          display: { lg: "block", md: "block", xs: "block" },
        }}
      >
        <Box sx={{ paddingY: "1%" }}>
          <Grid container lg={12} md={12} xs={12}>
            <Grid item lg={3} md={3} xs={12}>
              <Box sx={{ paddingTop: { lg: "0", md: "2%", xs: "3%" } }}>
                <Box
                  component="img"
                  src={logo}
                  alt="cedg"
                  sx={{ width: "40%" }}
                />{" "}
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              md={2}
              xs={12}
              sx={{
                display: { lg: "block", md: "block", xs: "none" },
              }}
            ></Grid>
            <Grid item lg={3} md={3} xs={12}>
              <Box
                sx={{
                  display: { xl: "flex", lg: "flex", md: "flex", xs: "none" },
                }}
              >
                <Box
                  sx={{
                    color: "#0249ae",
                    marginRight: { md: "2%", xs: "0", lg: "0", xl: "0" },
                  }}
                >
                  <MapIcon fontSize="large" />
                </Box>
                <Box
                  sx={{
                    textAlign: { lg: "left", md: "left", xs: "center" },
                    paddingLeft: { lg: "5%", md: "2%", xs: "0%" },
                    display: { lg: "block", md: "block", xs: "none" },
                  }}
                >
                  <Box>
                    <Typography
                      variant="p"
                      sx={{ fontSize: { lg: "20px", color: "#3B3838" } }}
                    >
                      Country
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{ fontSize: { lg: "12px" }, color: "#3B3838" }}
                    >
                      B2 Building, Capital Business Park, Sheikh Zayed.
                    </Typography>{" "}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              xs={12}
              sx={{
                display: { lg: "block", md: "block", xs: "none" },
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ color: "#0249ae" }}>
                  <MailOutlineIcon fontSize="large" />
                </Box>
                <Box
                  sx={{
                    textAlign: "left",
                    paddingLeft: { lg: "5%", md: "2%" },
                  }}
                >
                  <Box>
                    <Typography
                      variant="p"
                      sx={{ fontSize: { lg: "20px" }, color: "#3B3838" }}
                    >
                      info@cedg-oil.com
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{ fontSize: { lg: "12px" }, color: "#3B3838" }}
                    >
                      Office Hour 09:00 am - 05:00 pm
                    </Typography>{" "}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{
            backgroundColor: "#fff",
            marginTop: { lg: "0", md: "0", xs: "0px" },
          }}
        />
        <Box>
          <Box sx={{ paddingY: "1%", backgroundColor: "#3B3838" }}>
            <Grid container lg={12} md={12} xs={12}>
              <Grid item lg={12} md={8} xs={12}>
                <Box sx={{ zIndex: "10", position: "relative" }}>
                  <NavMenu />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
