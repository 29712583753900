import { Box } from "@mui/material";
import React from "react";
import GoldMine from "../../assets/Gold-Mine.png";
import AboutSection from "../../components/about-section/About";
import Team from "../../components/Team/Team";
import Heading from "../../components/Heading/Heading";
import SEO from "../../hooks/SEO";
const About = () => {
  return (
    <>
      {" "}
      <SEO
        title="CEDG Company | INTERNATIONAL FOR Petrolum Oiling"
        description="CEDG Company International For Petrolum Oiling Landing Page."
        name="CEDG Company"
        type="landing_page"
      />
      <Box sx={{ backgroundColor: "#161616", textAlign: "center" }}>
        <Box sx={{}}>
          <Box>
            <Heading head="About Us" img={GoldMine} />
          </Box>
        </Box>
        <Box>
          <AboutSection />
        </Box>
        <Box>
          {/*          <Team />
           */}{" "}
        </Box>
      </Box>
    </>
  );
};

export default About;
