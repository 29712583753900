import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MediaCard from "../info-card/MediaCard";
import { MediaData } from "../../data/mockData";
const KServices = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#161616",
          paddingBottom: "10%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            paddingTop: "5%",
          }}
        >
          {" "}
          <Box>
            <Grid
              container
              lg={6}
              md={12}
              sm={12}
              xs={12}
              rowSpacing={1}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    marginX: "45%",
                    borderRadius: "25px",
                    padding: "10px",
                    marginBottom: "2%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: { lg: "20px", md: "23px", xs: "23px" },
                      fontWeight: "400",
                      color: "#0249ae",
                    }}
                  >
                    Services{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  textAlign: {
                    lg: "center",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: {
                      lg: "3.6rem",
                      md: "3.6rem",
                      sm: "3.6rem",
                      xs: "2.3rem",
                      textAlign: {
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    },
                    fontWeight: "700",
                    lineHeight: "1.1em",
                    color: "#fff",
                  }}
                >
                  Mining Excellence,
                  <br /> Shaping Industries
                </Typography>
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  textAlign: {
                    lg: "center",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <Box
                  sx={{
                    paddingTop: "35px",
                    paddingBottom: "20px",
                    marginX: { lg: "20%", md: "25%", sm: "20%", xs: "10%" },
                    color: "#fff",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "20px",
                        sm: "20px",
                        xs: "20px",
                      },
                      fontWeight: "400",
                    }}
                  >
                    The mining industry has a unique culture and history, from
                    mining towns to songs about the struggles of miners. Mining
                    is not just a job, it's a way of life that requires
                    dedication, skill, and courage.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              lg={6}
              md={12}
              sm={12}
              xs={12}
              sx={{
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
                margin: "20px",
              }}
              rowSpacing={5}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {MediaData.map((item) => (
                <Box key={item.id} sx={{}}>
                  <Grid item>
                    <MediaCard
                      video={item.video}
                      title={item.title}
                      description={item.description}
                      to={item.to}
                      Link={item.link}
                    />
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Box>
          {/*<Box sx={{ marginTop: "5%" }}>
            <NavLink to="/heard-services">
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "#fff",
                  fontSize: "17px",
                  border: "solid 1px #ED7D31 ",
                  fontFamily: "sans-serif",
                  fontWeight: "200",
                  "&:hover": {
                    color: "#ED7D31",
                    border: "solid 1px #fff",
                  },
                }}
              >
                All Services
              </Button>
            </NavLink>
          </Box>*/}
        </Box>
      </Box>
    </>
  );
};

export default KServices;
