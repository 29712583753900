import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const InfoServiceCard = (props) => {
  const { title, description, icon, number, link, BColor } = props;
  return (
    <>
      <Box
        sx={{
          textAlign: "left",
          margin: "20px",
        }}
      >
        <Paper
          elevation={7}
          sx={{ padding: "10px", width: "100%", backgroundColor: { BColor } }}
        >
          <Box sx={{ paddingLeft: "0px", padding: "20px" }}>
            {props === icon ? (
              <Box component="img" src={icon} sx={{ width: "25%" }} />
            ) : (
              <Box sx={{ marginBottom: "-15px" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "40px",
                    fontWeight: "700",
                  }}
                >
                  {number}
                </Typography>
              </Box>
            )}
            <Box component="img" src={icon} sx={{ width: "25%" }} />
          </Box>
          <Box sx={{ paddingLeft: "10px" }}>
            <Divider
              orientation="horizontal"
              width="80px"
              sx={{
                border: "solid 2px #0249ae",
                backgroundColor: "#0249ae",
              }}
            />
          </Box>
          <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
            <Typography
              variant="p"
              sx={{ fontSize: "30px", fontWeight: "700", lineHeight: "1em" }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{ paddingLeft: "10px", paddingRight: "10px", paddingY: "8px" }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", fontWeight: "500" }}
            >
              {description}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "7px", paddingY: "5px" }}>
            <NavLink to={link}>
              <IconButton
                sx={{
                  color: "#0249ae",
                  fontWeight: "200",
                  fontSize: "16px",
                  "&:hover": {
                    color: "#000",
                    backgroundColor: "transparent",
                  },
                }}
              >
                Learn More
                <ArrowCircleRightIcon
                  sx={{ fontSize: "22px", paddingLeft: "5px" }}
                />
              </IconButton>
            </NavLink>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default InfoServiceCard;
