import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import LandingPhoto from "../../assets/landing.png";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

const Hero = () => {
  return (
    <>
      <Box sx={{ width: "auto", backgroundColor: "#fff" }}>
        <Grid container lg={12}>
          <Grid item xl={7} lg={7} md={6} xs={12}>
            <Box>
              <Box
                sx={{
                  paddingLeft: { lg: "7%", md: "7%", xs: "10%" },
                  paddingRight: { xl: "0", lg: "0", md: "0%", xs: "10%" },
                  marginTop: { xl: "5%", lg: "10%", xs: "5%" },
                  textAlign: "left",
                  zIndex: "10",
                  position: "relative",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: {
                      xl: "85px",
                      lg: "85px",
                      md: "55px",
                      sm: "3.5rem",
                      xs: "2.3rem",
                    },
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#161616",
                  }}
                >
                  Excavate,
                  <br />
                  Innovate, Elevate, And Succeed.
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  paddingLeft: { lg: "8%", md: "8%", xs: "10%" },
                  paddingRight: { xl: "0", lg: "0", md: "1", xs: "10%" },
                  display: "flex",
                  paddingTop: { lg: "5%", xl: "5%", xs: "10%" },
                }}
              >
                <Box sx={{ paddingRight: "15px" }}>
                  <Divider
                    orientation="vertical"
                    width="11px"
                    sx={{
                      border: "solid 2px #0249ae",
                      backgroundColor: "#0249ae",
                      height: { xl: "100px", lg: "100px", xs: "29vh" },
                    }}
                  />
                </Box>
                <Box sx={{ zIndex: "10" }}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: { lg: "25px", md: "20px", xs: "27px" },
                      fontWeight: "400",
                      color: "#161616",
                    }}
                  >
                    From the materials in our phones and laptops to the gold in
                    our jewelry, mining has a bigger impact on our daily lives
                    than we may realize.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: { lg: "8%", md: "8%", xs: "10%" },
                  paddingTop: { lg: "5%", md: "5%", xs: "2%" },
                }}
              >
                <IconButton
                  href="about"
                  sx={{
                    color: "#0249ae",
                    fontSize: { xs: "35px", lg: "25px", md: "25px" },
                    "&:hover": {
                      color: "#161616",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {" "}
                  Learn More{" "}
                  <PlayCircleIcon
                    sx={{
                      fontSize: { lg: "50px", md: "50px", xs: "50px" },
                      paddingLeft: "10px",
                    }}
                  />{" "}
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} xs={12}>
            <Box
              sx={{
                backgroundColor: "#0249ae",
                width: { xl: "90%", lg: "90%", md: "90%", xs: "80%" },
                height: { xl: "80vh", lg: "75vh", md: "55vh", xs: "45vh" },
                marginTop: { lg: "0%", md: "-20%", xs: "0%" },
                marginLeft: {
                  xl: "20%",
                  lg: "30%",
                  md: "30%",
                  sm: "30%",
                  xs: "10%",
                },
              }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} xs={12}>
            <Box
              component="img"
              src={LandingPhoto}
              sx={{
                marginTop: { xl: "-180%", lg: "-200%", md: "-20%", xs: "-90%" },
                marginLeft: { xl: "100%", lg: "20%", md: "-240%", xs: "0" },
                zIndex: "5",
                width: { xl: "300%", lg: "400%", md: "350%", xs: "100%" },
                marginBottom: { lg: "-0%", md: "-35%", xs: "10%" },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Hero;
