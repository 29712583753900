import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import gold from "../../assets/gold.png";

const About = () => {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#161616",
        }}
      >
        <Box sx={{ paddingTop: { xl: "0%", lg: "0%", md: "5%", xs: "5%" } }}>
          <Grid container lg={12} md={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  paddingY: "5%",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      marginX: "45%",
                      borderRadius: "25px",
                      padding: "10px",
                      marginBottom: "2%",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: { lg: "20px", md: "23px", xs: "23px" },
                        fontWeight: "400",
                        color: "#0249ae",
                      }}
                    >
                      About Us
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: { lg: "0", md: "0", xs: "10px" } }}>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: { lg: "80px", md: "60px", xs: "2.3rem" },
                        fontWeight: "700",
                        lineHeight: "1.1em",
                        color: "#fff",
                      }}
                    >
                      Beyond Limits,
                      <br /> Below the Surface
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        paddingTop: "50px",
                        paddingBottom: "20px",
                        textAlign: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                        marginX: {
                          xl: "25%",
                          lg: "25%",
                          md: "20%",
                          sm: "20%",
                          xs: "5%",
                        },
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          color: "#ddd",
                        }}
                      >
                        CEDG Company for Oiling was newly established in 2023
                        with an American-Egyptian partnership. The company aims
                        to expand its activities in all fields of Oiling and in
                        Egypt and the Middle East.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/*<Grid item xl={12} lg={12} md={12} xs={12}>
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  marginBottom: "10%",
                }}
              >
                <Box>
                  <NavLink to="/about">
                    <Button
                      href="about"
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: "#fff",
                        fontSize: "17px",
                        border: "solid 1px #0249ae ",
                        fontFamily: "sans-serif",
                        fontWeight: "200",
                        "&:hover": {
                          color: "#0249ae",
                          border: "solid 1px #fff",
                        },
                      }}
                    >
                      Know More
                    </Button>
                  </NavLink>
                </Box>
              </Box>
            </Grid>*/}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default About;
