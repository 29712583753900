import {
  Box,
  Breadcrumbs,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import GoldMine from "../../assets/Gold-Mine.png";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const Service = (props) => {
  const {
    header,
    MainImg,
    title,
    first,
    second,
    subtitle,
    last,
    img,
    listItem,
    listItem2,
    listItem3,
  } = props;
  const Navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    Navigate("/");
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      href="/"
      onClick={handleClick}
      style={{ color: "#0249ae", fontSize: "20px" }}
    >
      Home
    </Link>,
    <Typography
      key="3"
      sx={{ color: "#fff", fontSize: { xl: "20px", lg: "20px", xs: "20px" } }}
    >
      {header}
    </Typography>,
  ];
  return (
    <>
      <Box sx={{ backgroundColor: "#161616", textAlign: "center" }}>
        <Box sx={{}}>
          <Box
            sx={{
              height: { xl: "35vh", xs: "0", lg: "35vh" },
              objectFit: "cover",
              backgroundPosition: " center",
              backgroundRepeat: "repeat",
              backgroundImage: `url(${GoldMine})`,
              backgroundAttachment: "fixed",
              overflow: "hidden",
              textAlign: "center",
              position: "relative",
              backdropFilter: "blur(250px)",
            }}
          >
            <Box sx={{ paddingY: { xl: "5%", lg: "5%", xs: "15%" } }}>
              <Typography
                variant="p"
                sx={{
                  fontWeight: "Bold",
                  fontSize: "3.4rem",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {header}
              </Typography>
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  justifyItems: "center",
                  position: "relative",
                  display: "flex",
                  paddingY: "20px",
                }}
              >
                <Stack spacing={2} sx={{ textAlign: "center" }}>
                  <Breadcrumbs
                    separator=">"
                    sx={{ color: "#fff" }}
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container lg={12} sx={{ paddingX: "3%" }}>
            <Grid item lg={12} xl={12} xs={12}>
              <Box sx={{ padding: { xl: "5%", lg: "5%", xs: "0" } }}>
                <Box
                  component="img"
                  alt="Image"
                  src={MainImg}
                  sx={{ width: { xl: "100%", lg: "100%", xs: "100%" } }}
                />
              </Box>
              <Box sx={{ textAlign: "left", paddingX: "5%", marginTop: "0px" }}>
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    fontSize: { xl: "30px", lg: "30px", xs: "2.3rem" },
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  {title}{" "}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingLeft: "5%",
                  marginTop: "15px",
                  textAlign: "left",
                  lineHeight: "1.6em",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#ededed",
                  }}
                >
                  {first}
                </Typography>
              </Box>
              <Grid container lg={12}>
                <Grid item lg={7} xs={12}>
                  <Box
                    sx={{
                      textAlign: "left",
                      paddingLeft: { xl: "8.5%", lg: "8.5%", xs: "5%" },
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        fontSize: "30px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      {subtitle}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: { xl: "8%", lg: "8%", xs: "5%" },
                      marginTop: "15px",
                      textAlign: "left",
                      lineHeight: "1.6em",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#ededed",
                      }}
                    >
                      {second}
                    </Typography>
                  </Box>
                  <Box sx={{ paddingLeft: "5%" }}>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ color: "#0249ae" }}>
                          <ExpandCircleDownIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ fontSize: "30px", color: "#fff" }}>
                          {listItem}{" "}
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ color: "#0249ae" }}>
                          <ExpandCircleDownIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ fontSize: "30px", color: "#fff" }}>
                          {listItem2}{" "}
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ color: "#0249ae" }}>
                          <ExpandCircleDownIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ fontSize: "30px", color: "#fff" }}>
                          {listItem3}{" "}
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: "8%",
                      marginTop: "15px",
                      textAlign: "left",
                      lineHeight: "1.6em",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#ededed",
                      }}
                    >
                      {last}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={5}>
                  {" "}
                  <Box
                    sx={{
                      marginTop: "40px",
                      marginLeft: { xl: "40px", lg: "35px" },
                    }}
                  >
                    <Box
                      component="img"
                      alt="Image"
                      src={img}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Service;
